import makeStyles from '@material-ui/core/styles/makeStyles';
import { Centering, CreatePadding, FlexColumn } from '@theme_mixins';

import { GRAY_LIGHT } from '@theme_color';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        ...FlexColumn,
        alignItems: 'center',
    },
    authBlock: {
        ...Centering,
        width: '100%',
        height: '65vh',
        ...CreatePadding(15, 30, 15, 30),
        textAlign: 'center',
    },
    btnSigin: {
        marginBottom: 35,
        marginTop: 15,
    },
    span: {
        width: '100%',
        height: 20,
        background: GRAY_LIGHT,
    },
    loginBanner: {
        [theme.breakpoints.up('xsm')]: {
            height: '180px',
            marginTop: 0,
        },
        [theme.breakpoints.up('xmed')]: {
            height: '218px',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 0,
        },
    },
    titleWrapper: {
        padding: '0 1.5rem',
        [theme.breakpoints.up('xsm')]: {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            paddingBottom: '1.5rem',
        },
        [theme.breakpoints.up('xmed')]: {
            padding: '2rem 2rem 1rem',
        },
    },
    titleText: {
        fontFamily: 'Open Sans, sans-serif !important',
        margin: 0,
    },
    subTitleText: {
        fontFamily: 'Open Sans, sans-serif !important',
        fontWeight: '500',
        margin: 0,
        paddingTop: '0.25rem',
    },
    formWrapper: {
        width: '100%',
        display: 'grid',
        padding: '0 1.5rem',
        [theme.breakpoints.up('xmed')]: {
            padding: '0rem 2rem',
        },
        '& .MuiInputBase-input': {
            fontSize: 16,
            [theme.breakpoints.up('xsm')]: {
                padding: '9px 11px 12px',
            },
            [theme.breakpoints.up('xmed')]: {
                padding: '12px 11px 12px',
            },
        },
    },
    loginBoxWrapper: {
        // padding: '0.5rem 1.5rem',
        // [theme.breakpoints.up('xmed')]: {
        //     padding: '0rem 0rem',
        // },
    },
    phoneCodeLoginBox: {
        border: '1px solid #161616',
        height: '2.5rem',
        borderTopLeftRadius: '0.25rem',
        borderBottomLeftRadius: '0.25rem',
        [theme.breakpoints.up('xmed')]: {
            height: '3rem',
        },
    },
    phoneNumberLoginBox: {
        borderTop: '1px solid #161616',
        borderRight: '1px solid #161616',
        borderBottom: '1px solid #161616',
        height: '2.5rem',
        borderTopRightRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
        [theme.breakpoints.up('xmed')]: {
            height: '3rem',
        },
    },
    phoneCodeText: {
        fontFamily: 'Open Sans, sans-serif !important',
        fontSize: '1rem',
        paddingTop: '0.05rem',
        [theme.breakpoints.up('xsm')]: {
            margin: '9px',
        },
        [theme.breakpoints.up('xmed')]: {
            margin: '12px',
        },
    },
    footerWrapper: {
        padding: '4rem 0.5rem 1.5rem',
    },
    footerText: {
        fontFamily: 'Open Sans, sans-serif !important',
    },
    footerTextSecondary: {
        fontFamily: 'Open Sans, sans-serif !important',
    },
    btnLogin: {
        height: 40,
        fontSize: 16,
        width: '100%',
        maxWidth: '100%',
        marginTop: '1.2rem',
        textTransform: 'capitalize',
    },
    btnBeforeLogin: {
        marginTop: '1.2rem',
        textTransform: 'capitalize',
    },
    otpGroup: {
        marginTop: '1rem',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '360px',
        columnGap: '.75rem',
    },
    otpInput: {
        fontFamily: 'Open Sans, sans-serif !important',
        width: '2.5rem',
        height: '2.5rem',
        border: '1px solid #161616',
        borderRadius: '.25rem',
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        lineHeight: 1,
    },
    expiredOtpText: {
        fontFamily: 'Open Sans, sans-serif !important',
        paddingTop: '1.5rem',
        fontWeight: 'bold',
    },
    getOtpAgainText: {
        fontFamily: 'Open Sans, sans-serif !important',
    },
    redirectCustomerService: {
        cursor: 'pointer',
    },
    redirectCustomerServiceLogin: {
        cursor: 'pointer',
        position: 'relative',
        marginLeft: '23px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardIconWhatsappHelp: {
        color: '#89b434',
        fontSize: '24px',
        marginRight: '5px',
    },
    getOtpAgainTimer: {
        fontFamily: 'Open Sans, sans-serif !important',
    },
    showCounter: {
        padding: '.5rem',
    },
    countdownLink: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '700',
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        padding: '0.5rem',
        borderRadius: '0.25rem',
        textDecoration: 'none',
        color: '#000',
    },
    countdown: {
        fontFamily: 'Open Sans, sans-serif !important',
        lineHeight: '1.25rem',
        padding: '0 0.75rem 0 0.75rem',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            margin: '0',
        },
    },
    getOtpAgainWrapper: {
        margin: '0 1.5rem',
        [theme.breakpoints.up('xmed')]: {
            padding: '0rem 2rem',
        },
    },
    cardWhatsapp: {
        backgroundColor: '#89b434',
        cursor: 'pointer',
        padding: 0,
    },
    cardSMS: {
        backgroundColor: '#fbd144',
        cursor: 'pointer',
        padding: 0,
    },
    getOtpAgainButtonWrapper: {
        margin: '0 1.5rem',
        [theme.breakpoints.up('xmed')]: {
            padding: '0rem 2rem',
        },
    },
    cardContent: {
        padding: '.5rem !important',
        paddingBottom: '.75rem',
        paddingLeft: '16px !important',
        width: '100%',
    },
    cardIconWhatsapp: {
        color: '#fff',
        [theme.breakpoints.up('xsm')]: {
            fontSize: '2rem',
            marginTop: '0.15rem',
        },
        [theme.breakpoints.up('xmed')]: {
            fontSize: '2.1875rem',
        },
    },
    cardIconSms: {
        color: '#161616',
        [theme.breakpoints.up('xsm')]: {
            fontSize: '2rem',
            marginTop: '0.15rem',
        },
        [theme.breakpoints.up('xmed')]: {
            fontSize: '2.1875rem',
        },
    },
    cardTextWhatsapp: {
        color: '#fff',
        [theme.breakpoints.up('xsm')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up('xmed')]: {
            fontSize: '12px',
        },
    },
    cardTextSms: {
        color: '#161616',
        [theme.breakpoints.up('xsm')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up('xmed')]: {
            fontSize: '12px',
        },
    },
    cardSubTextWhatsapp: {
        padding: 0,
        margin: 0,
        color: '#fff',
        [theme.breakpoints.up('xsm')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up('xmed')]: {
            fontSize: '12px',
        },
    },
    cardSubTextSms: {
        padding: 0,
        margin: 0,
        color: '#161616',
        [theme.breakpoints.up('xsm')]: {
            fontSize: '10px',
        },
        [theme.breakpoints.up('xmed')]: {
            fontSize: '12px',
        },
    },
    my15: {
        marginTop: '15px !important',
        marginBottom: '15px !important',
    },
    bannerWrapper: {
        maxWidth: '320px',
        margin: '0 auto -1rem',
        padding: '0 2rem',
    },
    font16: {
        fontSize: '16px',
    },
    getOtpButton: {
        marginTop: '15px',
    },
}));
