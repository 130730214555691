import React from 'react';

import useStyles from '@core_modules/customer/pages/account/components/Guest/style';

const DateTimeDisplay = ({ value }) => {
    const styles = useStyles();
    const formattedNumber = value.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });

    return (
        <div className={styles.countdown}>
            <p>{formattedNumber}</p>
        </div>
    );
};

export default DateTimeDisplay;
