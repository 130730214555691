import DateTimeDisplay from '@core_modules/theme/components/header/desktop/plugins/CountdownTimer/DateTimeDisplay';
import { useCountdown, useCountdown2 } from '@core_modules/theme/components/header/desktop/plugins/CountdownTimer/hooks/useCountdown';
import React from 'react';

import Typography from '@common_typography';

import useStyles from '@core_modules/theme/components/header/desktop/plugins/style';

const ShowCounter = ({ minutes, seconds }) => {
    const styles = useStyles();

    return (
        <div className={styles.ShowCounter}>
            <div className={styles.countdownLink}>
                <Typography variant="h3" align="center" type="bold" className={styles.getOtpAgainTimer}>
                    OTP akan kadaluarsa dalam
                </Typography>
                <DateTimeDisplay value={minutes} />
                <p>:</p>
                <DateTimeDisplay value={seconds} />
            </div>
        </div>
    );
};

const CountdownTimer = ({ targetDate, otpSent, setExpiredTime }) => {
    const [minutes, seconds] = useCountdown(targetDate, otpSent);

    if (minutes + seconds <= 0) {
        setExpiredTime(true);
        return null;
    }
    return <ShowCounter minutes={minutes} seconds={seconds} />;
};

export const CountdownTimer2 = ({ targetDate, onCompleted }) => {
    const [minutes, seconds] = useCountdown2(targetDate, onCompleted);
    return <ShowCounter minutes={minutes} seconds={seconds} />;
};

export default CountdownTimer;
