/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';

const getReturnValues = (countDown) => {
    // calculate time left
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [minutes, seconds];
};

const useCountdown = (targetDate, otpSent) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (otpSent) {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [countDownDate]);

    return getReturnValues(countDown);
};

export const useCountdown2 = (targetDate, onCompleted) => {
    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        setCountDown(targetDate - new Date().getTime());
        const interval = setInterval(() => {
            const newCountDown = targetDate - new Date().getTime();
            if (newCountDown >= 0) {
                setCountDown(newCountDown);
            } else {
                setCountDown(0);
                onCompleted();
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [targetDate]);

    return getReturnValues(countDown);
};

export { useCountdown };
